import React from "react";
import { graphql } from "gatsby";
import ShopTemplate from "../../templates/shop";

const environment = {
  lang: "en",
  path: "/en/shop",
  altHrefLang: [
    {
      lang: "nl",
      path: "/shop",
    },
    {
      lang: "en",
      path: "/en/shop",
    },
  ],

  theme: "blue",
};

const ShopNl = (props) => {
  return <ShopTemplate {...props} environment={environment} />;
};

export default ShopNl;

export const query = graphql`
  query BookItemsQueryEn {
    books: allContentfulBook(
      filter: { node_locale: { eq: "en" } }
      sort: { fields: [createdAt], order: [DESC] }
    ) {
      edges {
        node {
          title
          slug
          price
          level
          subtitle
          fileIdSnipcart
          contentful_id
          numberOfPages
          node_locale
          productId
          image {
            fixed: gatsbyImageData(width: 150)
            main: gatsbyImageData(width: 200, layout: CONSTRAINED)
          }
          __typename
        }
      }
    }
  }
`;
